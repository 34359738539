@import './_variable.sass';
@import './_mixin.sass';

@mixin container-base {
    @include center-block;
    @include clearfix;
    padding-left: $container-padding-horizontal;
    padding-right: $container-padding-horizontal; }

@mixin make-container {
    @include container-base;
    @media (min-width: $screen-sm-min) {
        width: $screen-sm; }

    @media (min-width: $screen-md-min) {
        width: $screen-md; }

    @media (min-width: $screen-lg-min) {
        width: $screen-lg; } }

@mixin make-container-fluid {
    @include container-base;
    padding-left: $container-padding-horizontal;
    padding-right: $container-padding-horizontal; }


.container {
    @include make-container; }

.container-fluid {
    @include make-container-fluid; }




.row {
    @include clearfix; }


@include make-grid-columns();

@include make-grid(xs);

@media (min-width: $screen-sm-min) {
    @include make-grid(sm); }

@media (min-width: $screen-md-min) {
    @include make-grid(md); }

@media (min-width: $screen-lg-min) {
    @include make-grid(lg); }


.col--no-gutter {
    padding-left: 0;
    padding-right: 0; }
