.table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    margin-bottom: $line-height-computed;

    td, th {
        padding: $table-cell-padding; }

    th {
        word-break: keep-all;
        background-color: $table-head-bg;
        text-align: center; }

    tr {
        word-break: normal;
        background-color: $body-bg;
        border-bottom: solid 1px $table-border-color; } }


.table--condensed {
    td, th {
        padding: $table-condensed-cell-padding; } }

.table--bordered {
    border-radius: $table-border-radius;
    border: solid 1px $table-border-color;
    td, th {
        border: solid 1px $table-border-color; } }

.table {
    .table__head {
        border-bottom: 2px solid $brand-accent; } }
