.hidden {
    display: none; }

.clearfix {
    @include clearfix; }

.center-block {
    @include center-block; }

.pull-right {
    float: right !important; }

.pull-left {
    float: left !important; }

.text-hide {
    @include text-hide; }

.mono {
    font-family: $font-family-monospace; }

.serif {
    font-family: $font-family-serif; }


.text-small {
    font-size: $font-size-small; }
.text-large {
    font-size: $font-size-large; }


.text-left {
    text-align: left; }
.text-right {
    text-align: right; }
.text-center {
    text-align: center; }
.text-justify {
    text-align: justify; }
.text-nowrap {
    white-space: nowrap; }

.text-middle {
    vertical-align: middle; }

.text-lowercase {
    text-transform: lowercase; }
.text-uppercase {
    text-transform: uppercase; }
.text-capitalize {
    text-transform: capitalize; }

.text-muted {
    color: $text-muted; }

.list-unstyled {
    @include list-unstyled; }

.list-inline {
    @include list-unstyled;
    margin-left: -5px;

    & > li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px; } }


.text-primary {
    color: $brand-primary; }
.text-secondary {
    color: $brand-secondary; }
.text-accent {
    color: $brand-accent; }


.v-spacer {
    margin-bottom: $line-height-computed; }

.nobr {
    white-space: nowrap; }

.clickable {
    cursor: pointer; }

.inline-block {
    display: inline-block; }

.button-unstyled {
    @include button-non-decoration; }

.link-unstyled {
    @include anchor-non-decoration;
    color: $text-color;

    &:link, &:visited, &:hover {
        color: $text-color; } }


a.link-wrap {
    display: block;
    color: $text-color;
    @include anchor-non-decoration; }


.page-block {
    min-height: 100%; }

.no-mb {
    margin-bottom: 0; }

.memo {
    font-size: 10pt; }
