@mixin button-variant($color, $background, $border) {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 12%); }

    &:active,
    &.active,
    .open > &.dropdown-toggle {
        background-image: none; }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $background;
            border-color: $border; } } }


@mixin button-ghost-variant($color) {
    color: $color;
    background-color: transparent;
    border-color: $color;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $body-bg;
        background-color: $color;
 }        // border-color: darken($border, 12%)

    &:active,
    &.active,
    .open > &.dropdown-toggle {
        background-image: none; } }
