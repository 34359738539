@import '_variable.sass';
@import '_mixin.sass';

.toggle-menu {
    margin-bottom: 8px; }

.toggle-menu__toggler {
    font-size: 18px;
    a {
        @include anchor-non-decoration;
        display: block;
        padding: 4px 8px;
        color: $text-color; }

    &.toggle-menu__toggler--active {
        background-color: green; }

    .toggle-menu__toggler--disabled {
        a {
            color: $gray-light; } } }

.toggle-menu__toggler__icon {
    transition: all .1s ease; }

.toggle-menu__content {
    max-height: 0;
    height: 0;
    transition: max-height .2s ease;
    overflow: hidden;
    border-bottom: solid 1px $text-color;
    &.toggle-menu__content--shown {
        height: auto;
        overflow-y: scroll;
        max-height: 400px; } }


h1.caption {
    font-size: 24px;
    margin-top: 0; }

span.text-gray {
    color: $gray; }

.file-row {
    @include clearfix;
    min-height: 40px; }

.file-caption {
    min-height: 24px;
    line-height: 24px; }

.file-left-controls, .file-right-controls {
    color: red;
    height: 16px;
    line-height: 16px; }

.file-control {
    height: 16px;
    line-height: 16px;
    font-size: $font-size-small; }

.file-left-controls {
    float: left;
    .file-control {
        float: left;
        margin-right: 10px; } }

.file-right-controls {
    float: right;
    .file-control {
        float: right;
        margin-left: 10px;
        text-align: right;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 140px;
        min-width: 50px; } }
