@import '_variable.sass';
@import '_mixin.sass';

.hero {
    position: relative;
    overflow: hidden;
    height: 100%;
    min-height: 1000px; }
//    flex: 1
//    alignItems: 'center'
//    justifyContent: 'center'

.hero__bg {
    z-index: -1000;
    position: absolute;
    background: url("../img/pattern.gif");
    background-size: 256px 216px;
    background-repeat: repeat;
    $blur: 8px;
    filter: blur($blur) brightness(128%) contrast(84%);
    margin: -$blur;
    @include fill; }

.hero__welcome {
    @include centering; }

.hero__welcome__title {
    text-align: center;
    font-family: $font-family-sans-serif;
    font-size: 96px;
    @media (max-width: $grid-breakpoint) {
        font-size: 60px; }
    color: #333;

    margin-bottom: 24px; }

.hero__welcome__elocution {
    background-color: rgba(#fff, .7);
    width: $grid-breakpoint;
    padding: 16px 32px;
    @include center-block;
    @media (max-width: $grid-breakpoint) {
        width: 90%; } }

.hero__login {
    @include centering;

    max-width: 400px;
    padding: 30px 30px 10px;
    border-radius: $border-radius-base;
    background-color: rgba(#fff, .85); }

//    @media (max-width: $screen-xs-max)
//        max-width: 86%
//    max-width: 400px
//    position: relative
//   top: calc(50% + 20px)
//    transform: translateY(-50%)


@keyframes pulse {
    0% {
        transform: scale3d(1, 1, 1); }

    50% {
        transform: scale3d(1.05, 1.05, 1.05); }

    100% {
        transform: scale3d(1, 1, 1); } }
