.tabs {
    @include clearfix;
    background-color: $gray-more-lighter;
    height: $tab-height;
    @media (max-width: $screen-xs-max) {
        height: $tab-height-xs; }
    margin-bottom: $tab-margin-bottom; }

.tab {
    display: table-cell;
    width: 1%;

    padding: $tab-spacing ($tab-spacing / 2);
    background-color: $gray-more-lighter;

    & > a {
        @include anchor-non-decoration;
        display: block;
        background-color: $body-bg;

        text-align: center;

        height: $tab-link-height;
        line-height: $tab-link-height;
        font-size: $tab-font-size;
        @media (max-width: $screen-xs-max) {
            height: $tab-link-height-xs;
            line-height: $tab-link-height-xs;
            font-size: $tab-font-size-xs; }

        transition: background-color ease-in-out .1s, color ease-in-out .1s;
        &:hover {
            background-color: $brand-primary;
            color: $body-bg; } }

    position: relative;
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -$tab-link-arrow-size;
        width: 0;
        height: 0;
        border: solid $tab-link-arrow-size transparent;
        border-top-color: $brand-primary;

        top: $tab-link-height - $tab-link-arrow-size;
        @media (max-width: $screen-xs-max) {
            top: $tab-link-height-xs - $tab-link-arrow-size; }

        z-index: -1;
        transition: top ease-in-out .1s .2s;
        transition-delay: .2s; } }

.tab.tab--active {
    & > a {
        background-color: $brand-primary;
        color: $body-bg; }

    &:after {
        transition-delay: 0;
        top: $tab-link-height;
        @media (max-width: $screen-xs-max) {
            top: $tab-link-height-xs; } } }


// unstyle <ul>
ul.tabs {
    list-style: none;
    margin: 0;
    padding: 0; }

li.tab {
    margin: 0; }
