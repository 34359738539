//************
// util
//************

@mixin tab-focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }

@mixin clearfix {
    &:before, &:after {
        content: " ";
        display: table; }
    &:after {
        clear: both; } }

@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto; }

@mixin text-hide {
    font: #{0/0} a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0; }

@mixin fill {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

@mixin anchor-non-decoration {
    text-decoration: none;

    &:link, &:visited, &:hover {
        text-decoration: none; } }

@mixin button-non-decoration {
    border: 0;
    padding: 0;
    background-color: transparent;
    outline: 0;
    -webkit-appearance: none; }


@mixin list-unstyled {
    padding-left: 0;
    list-style: none; }

@mixin centering {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

//************
// responsive
//************

@import 'mixin/responsive.sass';


//************
// button
//************

@import 'mixin/button.sass';


//************
// alert
//************

@mixin alert-variant($background, $color, $border) {
    background-color: $background;
    color: $color;
    border: solid 1px $border; }


//************
// baricon
//************

@import 'mixin/baricon.sass';
