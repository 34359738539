//************
// color
//************

$gray-darker: #222;
$gray-dark: #333;
$gray: #555;
$gray-light: #777;
$gray-lighter: #c9c9c9;
$gray-more-lighter: #f5f5f5;

$border-color-base: $gray-lighter;

$shaded-bg-base: $gray-more-lighter;

$brand-primary: #57D180;
$brand-secondary: #FFAE6B;
$brand-accent: #EC638E;

$brand-primary-darker: #1BAB4C;
$brand-secondary-darker: #E97E25;
$brand-accent-darker: #CF2158;

$brand-primary-dark: #38BD65;
$brand-secondary-dark: #FF9D4C;
$brand-accent-dark: #E34477;

$brand-primary-light: #7FE3A1;
$brand-secondary-light: #FFC18F;
$brand-accent-light: #F388AA;

$brand-primary-lighter: #B1F3C7;
$brand-secondary-lighter: #FFD9BA;
$brand-accent-lighter: #FAB6CC;



//************
// basic
//************

$text-muted: $gray-light;
$body-bg: #fff;
$text-color: $gray-dark;
$link-color: $brand-primary;
$link-hover-color: $brand-primary-dark;

$border-radius-base: 2px;

$cursor-disabled: not-allowed;

$disabled-opacity: .65;


$font-family-sans-serif: "Open Sans", "Hiragino Kaku Gothic ProN", Meiryo sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, "Hiragino Mincho ProN", "游明朝", YuMincho, Meiryo, serif !default;

$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 16px;
$font-size-large: 18px;
$font-size-small: 14px;

$line-height-base: 1.428571429;
$line-height-computed: floor(($font-size-base * $line-height-base));

$font-size-h1: 32px;
$font-size-h2: 28px;
$font-size-h3: 24px;
$font-size-h4: $font-size-large;
$font-size-h5: $font-size-base;
$font-size-h6: $font-size-small;

$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.1;
$headings-color: inherit;
$headings-small-color: $gray-light;



//************
// responsive
//************

$screen-lg: 1200px;
$screen-lg-min: $screen-lg;

$screen-md: 960px;
$screen-md-max: $screen-lg-min - 1;
$screen-md-min: $screen-md;

$screen-sm: 764px;
$screen-sm-min: $screen-sm;
$screen-sm-max: $screen-md-min - 1;

$screen-xs-max: $screen-sm-min - 1;

$grid-breakpoint: $screen-sm;

$grid-columns: 12;
$grid-gutter: 30px;
$container-padding-horizontal: 16px;



//************
// alert
//************

$alert-padding: 16px;
$alert-border-radius: $border-radius-base;



//************
// baricon
//************

$baricon-default-color: $border-color-base;
$baricon-default-size: 100px;


//************
// button
//************

$btn-font-size: $font-size-base;
$btn-padding-v: 6px;
$btn-padding-h: 12px;
$btn-padding-v-lg: 10px;
$btn-padding-h-lg: 16px;
$btn-padding-v-sm: 5px;
$btn-padding-h-sm: 10px;
$btn-padding-v-xs: 1px;
$btn-padding-h-xs: 5px;



//************
// code
//************

$code-color: $brand-secondary-darker;
$code-bg: transparent;

$pre-bg: $shaded-bg-base;
$pre-color: $gray-dark;
$pre-border-color: transparent;
$pre-scrollable-max-height: 340px;



//************
// form
//************

$form-group-margin-bottom: 12px;
$form-label-padding-v: 6px;
$form-label-padding-h: 4px;
$form-label-color: $gray;

$form-control-padding-v: 6px;
$form-control-padding-h: 4px;
$form-control-font-size: 16px;

$form-control-border-color: $gray-lighter;
$form-control-placeholder-color: $gray-more-lighter;
$form-control-color: $gray;
$form-control-bg: #fdfdfd;

$form-control-toggle-label-margin: 6px;
$form-control-toggle-box-size: $form-control-font-size;
$form-control-toggle-inner-size: $form-control-font-size - 6px;

$form-control-default-focus-bg: rgba($brand-primary-lighter, .3);
$form-control-secondary-focus-bg: rgba($brand-secondary-lighter, .3);
$form-control-accent-focus-bg: rgba($brand-accent-lighter, .3);



//************
// navbar
//************

$navbar-bg: rgba($body-bg, .9);
$navbar-color: $link-color;
$navbar-active-bg: rgba(255, 255, 255, .9);
$navbar-active-color: $link-color;

$navbar-box-shadow: 0 0 2px rgba(#000, .1);


$navbar-height: 64px;
$navbar-height-sm: 56px;
$navbar-height-xs: 44px;

// navbar items collapse when xs
$navbar-item-height: $navbar-height;
$navbar-item-height-sm: $navbar-height-sm;
$navbar-item-height-xs: 36px;

$navbar-collapse-button-size: 32px;

$navbar-z: 200;



//************
// panel
//************

$panel-border-color: $border-color-base;
$panel-border-radius: $border-radius-base;
$panel-shasow: 0;

$panel-body-padding-v: 12px;
$panel-body-padding-h: 16px;
$panel-condensed-body-padding-v: 8px;
$panel-condensed-body-padding-h: 12px;

$panel-head-padding-v: 12px;
$panel-head-padding-h: 16px;
$panel-head-bg: $shaded-bg-base;
$panel-head-title-font-size: 20px;

$panel-condensed-head-padding-v: 8px;
$panel-condensed-head-padding-h: 12px;
$panel-condensed-head-title-font-size: 18px;

$panel-foot-padding-v: 12px;
$panel-foot-padding-h: 16px;
$panel-foot-bg: $shaded-bg-base;
$panel-condensed-foot-padding-v: 8px;
$panel-condensed-foot-padding-h: 12px;



//************
// search
//************

$search-padding-v: 6px;
$search-font-size: $font-size-small;
$search-color: $gray-light;

$search-condensed-padding-v: 3px;


//************
// tab
//************

$tab-padding: 8px 16px;
$tab-border-color: $gray-lighter;
$tab-margin-bottom: $line-height-computed;
$tab-height: 42px;
$tab-height-xs: 32px;

$tab-spacing: 2px;

$tab-link-height: $tab-height - ($tab-spacing * 2);
$tab-link-height-xs: $tab-height-xs - ($tab-spacing * 2);

$tab-link-arrow-size: 10px;

$tab-font-size: 16px;
$tab-font-size-xs: 13px;



//************
// table
//************

$table-cell-padding: 8px;
$table-condensed-cell-padding: 5px;
$table-bg: transparent;
$table-head-bg: $shaded-bg-base;
$table-border-color: $border-color-base;
$table-border-radius: $border-radius-base;



//************
// tooltip
//************

$tooltip-padding: 4px 8px;
$tooltip-z: 100;

$tooltip-arrow-size: 6px;
$tooltip-bg: $gray-darker;
$tooltip-color: $body-bg;
$tooltip-font-size: 12px;
$tooltip-border-radius: $border-radius-base;
$tooltip-color: #fff;
$tooltip-bg: #000;
$tooltip-opacity: .9;



//************
// well
//************

$well-bg: $gray-more-lighter;
$well-padding-v: 12px;
$well-padding-h: 16px;
$well-padding-v-lg: 16px;
$well-padding-h-lg: 24px;
$well-padding-v-sm: 4px;
$well-padding-h-sm: 8px;
$well-border: 0;
$well-border-radius: $border-radius-base;
