.baricon {
    position: relative;
    .baricon__bar {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 1;
        border-radius: 0;
        border-width: 0;
        border-style: solid;
        transition: all .2s ease-in-out 0; }

    @include baricon-color($baricon-default-color); }


.baricon.baricon--bars {
    .baricon__bar {
        &:nth-child(1) {
            transform: translate(0, -30%) scale(.8, .12); }
        &:nth-child(2) {
            transform: translate(0, 0%) scale(.8, .12); }
        &:nth-child(3) {
            transform: translate(0, 30%) scale(.8, .12); } } }


.baricon.baricon--times {
    .baricon__bar {
        &:nth-child(1) {
            transform: rotate(45deg) scale(.8, .12); }
        &:nth-child(2) {
            transform: scale(1.0, .01);
            opacity: 0; }
        &:nth-child(3) {
            transform: rotate(135deg) scale(.8, .12); } } }


.baricon.baricon--circle {
    .baricon__bar {
        &:nth-child(1) {
            transform: scale(1, 1) scale(.75, .75);
            border-radius: 50%;
            background-color: transparent;
            border-width: .16em; }
        &:nth-child(2) {
            transform: translate(0, 00%) scale(.8, .12);
            opacity: 0; }
        &:nth-child(3) {
            transform: translate(0, 30%) scale(.8, .12);
            opacity: 0; } } }


.baricon.baricon--check {
    .baricon__bar {
        &:nth-child(1) {
            transform: translate(-30%, 2%) rotate(60deg) scale(.4, .12); }
        &:nth-child(2) {
            transform: scale(.8, .12);
            opacity: 0; }
        &:nth-child(3) {
            transform: translate(8%, 0) rotate(150deg) scale(.8, .12); } } }


.baricon.baricon--heart {
    .baricon__bar {
        &:nth-child(1) {
            transform: translate(125%, 36%) rotate(-45deg);
            transform-origin: 0 100%;
            width: .40em;
            height: .64em;
            border-radius: .40em .40em 0 0; }
        &:nth-child(2) {
            transform: translate(0, 0) scale(.8, .12);
            opacity: 0; }
        &:nth-child(3) {
            transform: translate(25%, 36%) rotate(45deg);
            transform-origin: 100% 100%;
            width: .40em;
            height: .64em;
            border-radius: .40em .40em 0 0; } } }


.baricon.baricon--exclamation {
    .baricon__bar {
        &:nth-child(1) {
            transform: translate(0, -30) scale(.8, .12);
            opacity: 0; }
        &:nth-child(2) {
            transform: translate(0, -12%) scale(.16, .68); }
        &:nth-child(3) {
            transform: translate(0, 40%) scale(.16, .16); } } }


.baricon.baricon--question {
    .baricon__bar {
        &:nth-child(1) {
            transform: translate(16%, 0%) rotate(45deg) translate(0, -12%);
            border-width: .16em;
            width: .64em;
            height: .64em;
            background-color: transparent;
            border-radius: 50%;
            border-bottom-color: transparent; }
        &:nth-child(2) {
            transform: translate(0, 6%) scale(.16, .28); }
        &:nth-child(3) {
            transform: translate(0, 40%) scale(.16, .16); } } }


.baricon.baricon--star {
    .baricon__bar {
        &:nth-child(1) {
            transform: translate(0%, 110%);
            width: 0;
            height: 0;
            background-color: transparent;
            border-top-width: .35em;
            border-left-width: .5em;
            border-left-color: transparent;
            border-right-width: .5em;
            border-right-color: transparent;
            border-bottom-width: 0; }

        &:nth-child(2) {
            transform: translate(0%, 110%) rotate(-70deg);
            width: 0;
            height: 0;
            background-color: transparent;
            border-top-width: .35em;
            border-left-width: .5em;
            border-left-color: transparent;
            border-right-width: .5em;
            border-right-color: transparent;
            border-bottom-width: 0; }
        &:nth-child(3) {
            transform: translate(0%, 110%) rotate(70deg);
            width: 0;
            height: 0;
            background-color: transparent;
            border-top-width: .35em;
            border-left-width: .5em;
            border-left-color: transparent;
            border-right-width: .5em;
            border-right-color: transparent;
            border-bottom-width: 0; } } }


.baricon.baricon--intext {
    @include baricon-size($font-size-large);
    @include baricon-color($text-color); }

.baricon.baricon--primary {
    @include baricon-color($brand-primary); }

.baricon.baricon--secondary {
    @include baricon-color($brand-secondary); }

.baricon.baricon--accent {
    @include baricon-color($brand-accent); }
