.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    v-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    // white-space: nowrap
    user-select: none;

    padding: $btn-padding-v $btn-padding-h;
    font-size: $font-size-base;
    line-height: $line-height-base;
    border-radius: $border-radius-base;

    &,
    &:active,
    &.active {
        &:focus,
        &.focus {
            @include tab-focus; } }

    &:hover,
    &:focus,
    &.focus {
        text-decoration: none; } }


.btn--block {
    display: block;
    width: 100%; }


.btn[disabled], .btn--disabled {
    cursor: $cursor-disabled;
    pointer-events: none;
    opacity: $disabled-opacity;
    box-shadow: none; }

.btn {
    &.btn--lg {
        font-size: $font-size-large;
        padding: $btn-padding-v-sm $btn-padding-h-lg; }

    &.btn--sm {
        font-size: $font-size-small;
        padding: $btn-padding-v-sm $btn-padding-h-sm; }

    &.btn--xs {
        font-size: $font-size-small;
        padding: $btn-padding-v-xs $btn-padding-h-xs; } }



.btn:not(.btn--ghost) {
    @include button-variant(#333, #fff, #ccc);

    &.btn--primary {
        @include button-variant(#fff, $brand-primary, $brand-primary-dark); }

    &.btn--secondary {
        @include button-variant(#fff, $brand-secondary, $brand-secondary-dark); }

    &.btn--accent {
        @include button-variant(#fff, $brand-accent, $brand-accent-dark); } }


.btn.btn--ghost {
    @include button-ghost-variant($gray);

    &.btn--primary {
        @include button-ghost-variant($brand-primary); }

    &.btn--secondary {
        @include button-ghost-variant($brand-secondary); }

    &.btn--accent {
        @include button-ghost-variant($brand-accent); } }
