// custom.css for progress bar.
.CircularProgressbar-path {
    stroke: red; }

.CircularProgressbar-trail {
    stroke: gray; }

.CircularProgressbar-text {
    fill: yellow; }

.CircularProgressbar-background {
    fill: green; }

