@import './_variable.sass';
@import './_mixin.sass';

@mixin compute-search($font-size, $padding-v) {
    $h: $font-size * $line-height-base +  $padding-v * 2;
    padding: $padding-v $h / 2;
    font-size: $font-size;
    line-height: $line-height-base;
    border-radius: $h / 2; }


input.search {

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    display: block;
    color: $search-color;
    width: 100%;
    border: solid 1px $border-color-base;

    @include compute-search($search-font-size, $search-padding-v);

    &:focus {
        outline: 0;
        box-shadow:rgba($brand-primary, 0.65098) 0 0 1px 1px inset {} } }

input.search--block {
    display: block; }


input.search.search--condensed {
    @include compute-search($search-font-size, $search-condensed-padding-v); }
