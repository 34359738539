.well {
    background-color: $well-bg;
    padding: $well-padding-v $well-padding-h;
    border: $well-border;
    border-radius: $well-border-radius;
    margin-bottom: $line-height-computed; }

.well.well--lg {
    padding: $well-padding-v-lg $well-padding-h-lg; }

.well.well--sm {
    padding: $well-padding-v-sm $well-padding-h-sm; }
