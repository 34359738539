@import './_variable.sass';
@import './_mixin.sass';

.form {
    margin-bottom: $line-height-computed; }

.form__group {
    padding: 0;
    margin-bottom: $form-group-margin-bottom;
    @include clearfix; }


.form__label {
    display: block;
    padding: $form-label-padding-v $form-label-padding-h;
    font-size: 14px;
    color: $form-label-color; }


.form__label--right {
    text-align: right;
    padding-right: 12px; }



.form__error {
    &[disabled] {
        color: $brand-accent;
        font-size: 14px; } }



.form__control {
    padding: $form-control-padding-v $form-control-padding-h;
    font-size: $form-control-font-size;
    line-height: $line-height-base;
    display: block;
    width: 100%;
    border: 0;
    &[disabled] {
        cursor: $cursor-disabled;
        opacity: $disabled-opacity; } }



textbox.form__control {
    height: auto; }



input[type="text"],
input[type="number"],
input[type="date"],
input[type="email"],
input[type="password"],
textbox {
    &.form__control {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        background-color: $form-control-bg;
        border-radius: 0;
        border-bottom: solid 2px $form-control-border-color;
        color: $form-control-color;
        transition: border-color ease-in-out .2s, background-color ease-in-out .2s;

        &:focus {
            outline: 0;
            border-color: $brand-primary;
            background-color: $form-control-default-focus-bg; }

        .form__control--secondary {
            border-color: $brand-secondary;
            &:focus {
                background-color: $form-control-secondary-focus-bg; } }

        .form__control--accent, &.ng-invalid.ng-dirty {
            border-color: $brand-accent;
            &:focus {
                background-color: $form-control-accent-focus-bg; } }

        &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder {
            // color: $form-control-placeholder-color
            color: red; } } }


input[type="radio"].form__control,
input[type="checkbox"].form__control {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
    & + label.form__label {
        user-select: none;
        position: relative;
        padding-left: $form-control-toggle-box-size + $form-control-toggle-label-margin;
        cursor: pointer;
        &:before, &:after {
            content: "";
            display: block;
            position: absolute; }

        &:before {
            top: 50%;
            left: 0;
            margin-top: - $form-control-toggle-box-size / 2;
            width: $form-control-toggle-box-size;
            height: $form-control-toggle-box-size;
            border: 2px solid $form-control-border-color;
            background-color: $body-bg; }
        &:after {
            top: 50%;
            left: 0;
            margin-top: - $form-control-toggle-inner-size / 2;
            margin-left: ($form-control-toggle-box-size - $form-control-toggle-inner-size) / 2;
            width: $form-control-toggle-inner-size;
            height: $form-control-toggle-inner-size;

            background-color: $brand-primary;

            opacity: 0;
            transition: opacity ease-in-out .2s; } }


    &:focus + label.form__label {
        background-color: $form-control-default-focus-bg; }


    &:hover + label.form__label {
        &:after {
            opacity: .4; } }

    &:checked + label.form__label {
        &:after {
            opacity: 1; } }

    &:checked:hover + label.form__label {
        &:after {
            opacity: .9; } } }



input[type="radio"].form__control {
    & + label.form__label {
        &:before {
            border-radius: $form-control-toggle-box-size / 2; }
        &:after {
            border-radius: $form-control-toggle-inner-size / 2; } } }



select.form__control {
    font-size: $form-control-font-size - 2px;
    background-color: $body-bg;
    border: solid 2px $form-control-border-color;
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-color: $form-control-bg;
    background-image: url(/img/arrow.svg);
    background-repeat: no-repeat;
    background-position: right 4px center;
    background-size: 16px;

    overflow: hidden; }


textarea.form__control {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-color: $form-control-bg;
    border-radius: 0;
    border: solid 2px $form-control-border-color;
    color: $form-control-color;
    transition: border-color ease-in-out .2s, background-color ease-in-out .2s;

    &:focus {
        outline: 0;
        border-color: $brand-primary;
        background-color: $form-control-default-focus-bg; }

    .form__control--secondary {
        border-color: $brand-secondary;
        &:focus {
            background-color: $form-control-secondary-focus-bg; } }

    .form__control--accent, &.ng-invalid.ng-dirty {
        border-color: $brand-accent;
        &:focus {
            background-color: $form-control-accent-focus-bg; } } }


.droparea {
    padding: $line-height-computed 0;
    line-height: $line-height-computed;
    text-align: center;
    font-size: $font-size-base;

    border: dotted 1px #ccc;
    background-color: #eee;

    &:hover {
        background-color: #ddd; } }
