


@mixin make-grid-columns($i: 1, $list: ".col--xs-#{$i}, .col--sm-#{$i}, .col--md-#{$i}, .col--lg-#{$i}") {
    @for $i from (1 + 1) through $grid-columns {
        $list: "#{$list}, .col--xs-#{$i}, .col--sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}"; }

    #{$list} {
        position: relative;
        min-height: 1px; } }

@mixin float-grid-columns($class, $i: 1, $list: ".col--#{$class}-#{$i}") {
    @for $i from (1 + 1) through $grid-columns {
        $list: "#{$list}, .col--#{$class}-#{$i}"; }
    #{$list} {
        float: left; } }


@mixin calc-grid-column($index, $class, $type) {
    @if ($type == width) and ($index > 0) {
        .col--#{$class}-#{$index} {
            width: percentage(($index / $grid-columns));
            padding-left: $grid-gutter / 2;
            padding-right: $grid-gutter / 2; } }

    @if ($type == push) and ($index > 0) {
        .col--#{$class}-push-#{$index} {
            left: percentage(($index / $grid-columns)); } }

    @if ($type == push) and ($index == 0) {
        .col--#{$class}-push-0 {
            left: auto; } }

    @if ($type == pull) and ($index > 0) {
        .col--#{$class}-pull-#{$index} {
            right: percentage(($index / $grid-columns)); } }

    @if ($type == pull) and ($index == 0) {
        .col--#{$class}-pull-0 {
            right: auto; } }

    @if ($type == offset) and ($index > 0) {
        .col--#{$class}-offset-#{$index} {
            margin-left: percentage(($index / $grid-columns)); } } }


@mixin loop-grid-columns($columns, $class, $type) {
    @for $i from 0 through $columns {
        @include calc-grid-column($i, $class, $type); } }


@mixin make-grid($class) {
    @include float-grid-columns($class);
    @include loop-grid-columns($grid-columns, $class, width);
    @include loop-grid-columns($grid-columns, $class, pull);
    @include loop-grid-columns($grid-columns, $class, push);
    @include loop-grid-columns($grid-columns, $class, offset); }
