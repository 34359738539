
* {
    box-sizing: border-box;
    &:before, &:after {
        box-sizing: border-box; } }


html,body {
    height: 100%; }


html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0,0,0,0); }


body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $text-color;
    background-color: $body-bg; }


a {
    color: $link-color;
    text-decoration: none;

    &:hover, &:focus {
        color: $link-hover-color;
        text-decoration: underline; }

    &:focus {
        @include tab-focus; }

    &[ng-click] {
        cursor: pointer; } }


img {
    vertical-align: middle; }


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;

    small, .small {
        font-weight: normal;
        line-height: 1;
        color: $headings-small-color; } }


h1, .h1,
h2, .h2,
h3, .h3 {
    margin-top: $line-height-computed;
    margin-bottom: ($line-height-computed / 2);

    small, .small {
        font-size: 65%; } }


h4, .h4,
h5, .h5,
h6, .h6 {
    margin-top: ($line-height-computed / 2);
    margin-bottom: ($line-height-computed / 2);

    small, .small {
        font-size: 75%; } }


h1, .h1 {
    font-size: $font-size-h1; }
h2, .h2 {
    font-size: $font-size-h2; }
h3, .h3 {
    font-size: $font-size-h3; }
h4, .h4 {
    font-size: $font-size-h4; }
h5, .h5 {
    font-size: $font-size-h5; }
h6, .h6 {
    font-size: $font-size-h6; }


p {
    margin: 0 0 ($line-height-computed / 2); }


ul, ol {
    margin-top: 0;
    margin-bottom: ($line-height-computed / 2);
    ul,ol {
        margin-bottom: 0; } }


hr {
    border: 0;
    border-bottom: solid 1px $border-color-base;
    &.dotted {
        border-style: dotted; } }


dl {
    margin-top: 0;
    margin-bottom: $line-height-computed; }

dt, dd {
  line-height: $line-height-base; }

dt {
    font-weight: bold; }

dd {
    margin-left: 0; }


input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
