@import './_variable.sass';
@import './_mixin.sass';

@-ms-viewport {
    width: device-width; }



.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-xs-table-cell,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-sm-table-cell,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-md-table-cell,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {}
.visible-lg-table-cell {
    display: none; }

.visible-xs-block {
    @media (max-width: $screen-xs-max) {
        display: block !important; } }
.visible-xs-inline {
    @media (max-width: $screen-xs-max) {
        display: inline !important; } }
.visible-xs-inline-block {
    @media (max-width: $screen-xs-max) {
        display: inline-block !important; } }
.visible-xs-table-cell {
    @media (max-width: $screen-xs-max) {
        display: inline-block !important; } }



.visible-sm-block {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        display: block !important; } }
.visible-sm-inline {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        display: inline !important; } }
.visible-sm-inline-block {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        display: inline-block !important; } }
.visible-sm-table-cell {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        display: table-cell !important; } }



.visible-md-block {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        display: block !important; } }
.visible-md-inline {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        display: inline !important; } }
.visible-md-inline-block {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        display: inline-block !important; } }
.visible-md-table-cell {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        display: table-cell !important; } }



.visible-lg-block {
    @media (min-width: $screen-lg-min) {
        display: block !important; } }
.visible-lg-inline {
    @media (min-width: $screen-lg-min) {
        display: inline !important; } }
.visible-lg-inline-block {
    @media (min-width: $screen-lg-min) {
        display: inline-block !important; } }
.visible-lg-table-cell {
    @media (min-width: $screen-lg-min) {
        display: table-cell !important; } }



.hidden-xs {
    @media (max-width: $screen-xs-max) {
        display: none !important; } }

.hidden-sm {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        display: none !important; } }

.hidden-md {
    @media (min-width: $screen-md-min) and (max-width: $screen-xs-max) {
        display: none !important; } }

.hidden-lg {
    @media (min-width: $screen-xs-max) {
        display: none !important; } }


.hidden-from-sm {
    @media (min-width: $screen-sm-min) {
        display: none !important; } }
.hidden-from-md {
    @media (min-width: $screen-md-min) {
        display: none !important; } }
.hidden-from-lg {
    @media (min-width: $screen-lg-min) {
        display: none !important; } }


.hidden-to-xs {
    @media (max-width: $screen-xs-max) {
        display: none !important; } }
.hidden-to-sm {
    @media (max-width: $screen-sm-max) {
        display: none !important; } }
.hidden-to-md {
    @media (max-width: $screen-md-max) {
        display: none !important; } }




div[responsive-width-getter] {
    position: fixed;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    z-index: 0;
    @media (min-width: $screen-sm-min) {
        z-index: 1; }

    @media (min-width: $screen-md-min) {
        z-index: 2; }

    @media (min-width: $screen-lg-min) {
        z-index: 3; } }
