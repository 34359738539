@import './_variable.sass';
@import './_mixin.sass';
.alert {
    position: relative;
    margin-bottom: $line-height-computed;
    padding: $alert-padding;
    border-radius:  $alert-border-radius;
    a {
        color: inherit;
        font-weight: bold; } }

.alert--primary {
    @include alert-variant($brand-primary-lighter, $brand-primary-darker, $brand-primary); }
.alert--secondary {
    @include alert-variant($brand-secondary-lighter, $brand-secondary-darker, $brand-secondary); }
.alert--accent {
    @include alert-variant($brand-accent-lighter, $brand-accent-darker, $brand-accent); }


.alert__close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    font-size: 20px;
    left: auto;

    color: inherit; }

button.alert__close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none; }
